import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import HealthCareHeading from "../Components/Industries/HealthcareHeading";
import HealthContents from "../Components/Industries/HealthContents";

function HealthCare() {
    return (
      <div>
      <Navbar/>
<HealthCareHeading/>
<HealthContents/>
      <Footer/>
      </div> 
    );
  }
  export default HealthCare;
  