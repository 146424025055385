import "./Insights.css"

import { AIItems, BusinessItems } from "./InsightsContentsItems";
function AIContents() {
return(
    <>
{AIItems.map((items)=>
{return(
    <li key={items.id} >
   <div className="flex Insights container">
   <div className="Insights-desc ">
        <p>{items.text}</p>
    </div>
 
    <div className="Insights-desc">
        <h1>{items.headingtext}</h1>
    </div>
    </div>
    </li>
)}
)}
</>
)}

export default AIContents;
