import Image from "../../assets (1)/sustainability1.jpg";
import "./Insights.css"

import { SustainibiltyItems } from "./InsightsContentsItems";
function SustainContents() {
return(
    <>
{SustainibiltyItems.map((items)=>
{return(
    <li key={items.id} >
   <div className="flex Insights">
   <div className="Insights-desc">
        <p>{items.text}</p>
    </div>
    <div className=" InsightImg" ><img src={Image} alt="..." /></div>
    </div>
    </li>
)}
)}
</>
)}

export default SustainContents;
