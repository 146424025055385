import "./RoundTitle.css"

function RetailHeading() {
    return (
 <div className="main-round">
<div className="round">
<h2>Retail</h2>
</div>
 </div>       
    );
  }
  export default RetailHeading;
  