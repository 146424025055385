import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import SkillManagementContents from "../Components/SkillManagement/SkillManagementContents";


function SkillManagement() {
    return (
      <div>
      <Navbar/>
 <SkillManagementContents/>
      <Footer/>
      </div> 
    );
  }
  export default SkillManagement;
  