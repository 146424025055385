import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import InsuranceHeading from "../Components/Industries/InsuranceHeading";
import InsuranceContents from "../Components/Industries/InsuranceContents";

function Insurance() {
    return (
      <div>
      <Navbar/>
<InsuranceHeading/>
<InsuranceContents/>
      <Footer/>
      </div> 
    );
  }
  export default Insurance;
  