import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import Contacts from "../Components/Contacts/Contacts";
function Contact() {
  return (
    <>
      <Navbar />
   <Contacts/>
      <Footer />
    </>
  );
}
export default Contact;
