import {
HeroCardItemsRow1,
HeroCardItemsRow2,
HeroCardItemsRow3,
} from "./HeroCardItems";
import "./HeroCard.css";
function HeroCardDetail() {
return (
<>
<div className="flex cardcont">
<div className="flex cardrows">
  {HeroCardItemsRow1.map((Herocards) => {
    return (
      <div>
        <li className="hero-cards" key={Herocards.id}>
          <div className="cardHeading">
            <h4>{Herocards.heading}</h4>
            <p>{Herocards.paragraph}</p>
          </div>
        </li>
      </div>
    );
  })}
</div>

<div className="flex cardrows">
  {HeroCardItemsRow2.map((Herocards) => {
    return (
      <div>
        <li className="hero-cards" key={Herocards.id}>
          <div className="cardHeading">
            <h4>{Herocards.heading}</h4>
            <p>{Herocards.paragraph}</p>
          </div>
        </li>
      </div>
    );
  })}
</div>

<div className="flex cardrows">
  {HeroCardItemsRow3.map((Herocards) => {
    return (
      <div>
        <li className="hero-cards" key={Herocards.id}>
          <div className="cardHeading">
            <h4>{Herocards.heading}</h4>
            <p>{Herocards.paragraph}</p>
          </div>
        </li>
      </div>
    );
  })}
</div>
</div>
</>
);
}

export default HeroCardDetail;
