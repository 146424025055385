export const ContactsContactItems = [
    {
      address: "Patna Office:- ",
      name: "Nestif Technologies,",
      add1: "E-101, Dream Jewels Apartment",
      add2: "RK Puram Phase-1, Danapur, Patna - 801503"
    },
    {
      address: "Bhagalpur Office:- ",
      name: "Nestif Technologies,",
      add1: "Satish Sarkar Lane,",
      add2: "Adampur,Bhagalpur,Bihar- 812001"
    }
  ];
  