import ContactForm from "./ContactForm";
import ContactDetail from "./ContactsContact";
import "./Contact.css";
import Chatmodal from "../../Chatcomponent/Chatcomponent/Chatmodal";
function Contacts() {
  return (
    <div className="flex contact Home-Container">
    <div className="contactform">    <ContactForm/></div>
    <div className="contactdetail">   <ContactDetail/> </div>
 {/* <div className="request">
  <Chatmodal/>
 </div> */}
    </div>
  );
}
export default Contacts;
