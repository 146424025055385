
export const SocialMediaItems = [
    {id:0,
      icon: "fa-brands fa-facebook",
      cName: "Social-links",
      clName: "Social-links-facebook",
      url: "https://www.facebook.com/people/Nestif-Technologies/100095323951335/?mibextid=ZbWKwL"
    },
    {
      id:1,
      icon: "fa-brands fa-instagram",
      
      cName: "Social-links",
      clName: "Social-links-instagram"
    },
  
    {
      id:2,
      icon: "fa-brands fa-twitter",
      url: "https://twitter.com/NestifTech",
      cName: "Social-links",
      clName: "Social-links-twitter"
    },
    {id:3,
      icon: "fa-brands fa-linkedin",
      url: "https://www.linkedin.com/company/nestif-technologies",
      cName: "Social-links",
      clName: "Social-links-linkedin"
    },
    {id:4,
      icon: "fa-brands fa-youtube",
      cName: "Social-links",
      clName: "Social-links-youtube",
      url: "https://www.youtube.com/@nestiftechnologies4330"
    }
  ];
  