import "./RoundTitle.css"

function HealthCareHeading() {
    return (
 <div className="main-round">
<div className="round">
<h2>Health</h2>
</div>
 </div>       
    );
  }
  export default HealthCareHeading;
  