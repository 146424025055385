const OurStoryItems = [
    {
      id: 1,
      text:
        "Hey there! we, a group of colleagues, created an IT consulting and custom software development company. Since 2021, we have provided a wide range of software development services for our clients. Our core services include software engineering, digital consulting, blockchain implementation, as well as UX/UI design, quality assurance, and legacy software modernization. Currently, we have a team of 10+ software developers. Our management and supporting executive complement us for seamless service delivery."
    },
    
  ];
  
 
  export default OurStoryItems;
  