import Footer from "../Components/Footer/Footer";
import Navbar from "../Components/Navbar/Navbar";
import "./ErrorPage.css";

function ErrorPage() {
    return (
      <>
 <Navbar/>
      <div className="error">
        <h1>NOT APPLICABLE</h1>
      </div>
 <Footer/>

      </>
     
    
    );
  }
  export default ErrorPage;
  