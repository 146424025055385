import "./Insights.css";

import { CyberItems } from "./InsightsContentsItems";
function CyberContents() {
  return (
    <>
      {CyberItems.map((items) => {
        return (
          <li key={items.id}>
            <div className="flex Insights container">
              <div className="Insights-desc">
                <p>{items.text}</p>
              </div>
        
              <div className="Insights-desc">
                <h1>{items.headingtext}</h1>
              </div>
            </div>
          </li>
        );
      })}
    </>
  );
}

export default CyberContents;
