const ImageGalleryItems = [
    {
      id: 1,
      img: "4.jpg",
    },
    {
        id: 2,
        img: "5.jpg",
      },
      {
        id: 3,
        img: "3.jpg",
      },
      {
        id: 4,
        img: "1.jpg",
      },
      {
        id: 5,
        img: "2.jpg",
      },
      {
        id: 6,
        img: "6.jpg",
      },
     
  ];
  
  export default ImageGalleryItems;
  