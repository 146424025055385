import "./RoundTitle.css"

function AviationHeading() {
    return (
 <div className="main-round">
<div className="round">
<h2>Aviation</h2>
</div>
 </div>       
    );
  }
  export default AviationHeading;
  