import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import DigitalTransHeading from "../Components/Insights/DigitalTransHeading";
import DigitalContents from "../Components/Insights/DigitalContents";

function DigitalTransform() {
    return (
      <div>
      <Navbar/>
<DigitalTransHeading/>
<DigitalContents/>
      <Footer/>
      </div> 
    );
  }
  export default DigitalTransform;
  