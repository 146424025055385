import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import AviationHeading from "../Components/Industries/AviationHeading";
import AviationContents from "../Components/Industries/AviationContents";

function Aviation() {
    return (
      <div>
      <Navbar/>
<div>
<AviationHeading/>
<AviationContents/>
</div>
      <Footer/>
      </div> 
    );
  }
  export default Aviation;
  