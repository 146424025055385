import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";

import Consulting from "../Components/SoftwareConsulting/Consulting";

function SoftwareCons() {
    return (
      <div>
      <Navbar/>
       <Consulting/>
      <Footer/>
      </div> 
    );
  }
  export default SoftwareCons;
  