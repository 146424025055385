import "./Footer.css";
import Copyright from "./Copyright";

import ContactDetail from "./ContactDetail";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer>
      <div className="Footer-container Home-Container flex">
        <div className="contact">
          <ContactDetail />
        </div>
        <div className="links flex">
          <div>
            <h4>Company</h4>
            <Link to="/about">About Us</Link>
            <Link to="/careers">Careers</Link>
            <Link to="/media">Media</Link>
            <Link to="/contacts">Contact</Link>
          </div>
          <div>
            <h4>Services</h4>
            <Link to="/softwareconsulting">Software Consulting</Link>
            <Link to="/workforcemanagement">Workforce Management</Link>
            <Link to="/productEngg">Product Engineering</Link>
            <Link to="/skilldevelopment">Skill Development</Link>
           
          </div>
          <div>
            <h4>Insights</h4>
            <Link to="/artificialintelligence">Artificial Intelligence</Link>
            <Link to="/cloud">Cloud</Link>
            <Link to="/cybersecurity">Cyber Security</Link>
            <Link to="/digitaltransformation">Digital Transformation</Link>
          </div>
          <div>
            <h4>Industries</h4>
            <Link to="/aviation">Aviation</Link>
            <Link to="/education">Education</Link>
            <Link to="/healthcare">Healthcare</Link>
            <Link to="/insurance">Insurance</Link>
          </div>
        </div>
      </div>
      <hr />
      <Copyright />
    </footer>
  );
};
export default Footer;
