import ErrorPage from "../Pages/ErrorPage";


function Media() {
  return (
    <>
      <ErrorPage />
    </>
  );
}
export default Media;
