
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import HeroCard from "../Components/ProductEngg/HeroCard/HeroCard";
function ProductEngg() {
    return (
      <div>
       <Navbar/>
        <HeroCard/>
     <Footer/>
      </div> 
    );
  }
  export default ProductEngg;
  