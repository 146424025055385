import Image from "../../assets (1)/arrows-2899888_960_720.jpg";
import "./Insights.css"

import { CloudItems } from "./InsightsContentsItems";
function CloudContents() {
return(
    <>
{CloudItems.map((items)=>
{return(
    <li key={items.id} >
   <div className="flex Insights container">
   <div className="Insights-desc">
       
        <p>{items.text}</p>
    </div>
  
    <div className="Insights-desc">
        <h1>{items.headingtext}</h1>
    </div>
    </div>
    </li>
)}
)}
</>
)}

export default CloudContents;
