import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import RetailHeading from "../Components/Industries/RetailHeading";
import RetailContents from "../Components/Industries/RetailContents";

function Retail() {
    return (
      <div>
      <Navbar/>
<RetailHeading/>
<RetailContents/>
      <Footer/>
      </div> 
    );
  }
  export default Retail;
  