import React, { useState,useRef} from "react";
import "./ContactForm.css";
import emailjs from "@emailjs/browser";
function ContactForm() {
  const form = useRef();
  const [isSubmit, setIsSubmit] = useState(false);
  const [userData, setUserData] = useState({
    fullname: "",
    phone: "",
    email: "",
    message: "",
  });
  const showmessage = () => {
    setTimeout(() => {
      setIsSubmit(false);
    }, 2000);
  };
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_v2e49mn",
        "template_cmetcdt",
        form.current,
        "_DdKW42-Zlnq_2VoL"
        // "iKDR9VZ3nSfs6LrmU"
      )
      .then(
        (result) => {
          setIsSubmit(true);
          showmessage();
        },
        (error) => {
          console.log(error.text);
        }
      );

    setUserData({
      fullname: "",
      phone: "",
      email: "",
      message: "",
    });
  };
  return (
    <div className="form-container">
      <h1>Contact Our Team</h1>
      <form method="POST" onSubmit={sendEmail} ref={form}>
        <input
          placeholder="Enter your name"
          name="fullname"
          value={userData.fullname}
          onChange={postUserData}
        />
        <input
          placeholder="Enter your Email"
          required
          name="email"
          value={userData.email}
          onChange={postUserData}
        />
        <input
          placeholder="Phone no."
          name="phone"
          value={userData.phone}
          onChange={postUserData}
        />
        <textarea
          placeholder="How can we help you?"
          rows="2"
          name="message"
          value={userData.message}
          onChange={postUserData}
        ></textarea>
        <button>Submit</button>
      </form>
      {isSubmit && (
        <div
          className="alert alert-primary w-60 m-auto mt-3 text-secondary text-center"
          role="alert"
        >
          Form submitted, our business team will reach out to you shortly.
        </div>
      )}
    </div>
  );
}
export default ContactForm;
