import ImageGallery from "./ImageGallery";
import "./GallerySection.css";

function GallerySection() {
  return (
    <>
      <div className=" Gallery-cont">
        <ImageGallery />
      </div>
    </>
  );
}

export default GallerySection;
