const TextImgTextContents = [
    {
      id: 1,
      heading1:"Evolving how you go to market.",
      heading2:"Designing efficient marketing campaigns.",
      heading3:"Connecting marketing and sales.",
      heading4:"Analyzing and bringing insights from the customer data.",
      text1:"We create robust goto market strategy to bring better customer experience.",    
      text2:"We prepare powered by modern technological developments like artificial intelligence and machine learning for optimization and efficiency.",
      text3:"We deploy hardworking salesforce with the capability to convert there pitch into sales.",
      text4:"Our customer strategies are based on analysis of customer data. They are then backed by efficient campaigns"
},
];
  
  export default TextImgTextContents;