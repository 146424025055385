export const ContactItems = [
    {
      title: "E-mail ID: ",
      text: "nestiftechnologies@gmail.com"
    },
    {
      title: "Ph.no: ",
      text: "+91-8677066576"
    },
    {
      title: "Office: ",
      text: "Hyderabad"
    },
    {
      title: "Regd: Office: ",
      text: " S S Lane, Adampur, Bhagalpur"
    }
  ];
  